import { assign, log, raise, setup } from 'xstate';
export type NavigationMachineEvents =
  | { type: 'START', to: string }
  | { type: 'START_LOAD' }
  | { type: 'START_LOAD' }
  | { type: 'COMPLETE_LOAD' }
  | { type: 'START_IN_TRANSITION' }
  | { type: 'COMPLETE_IN_TRANSITION' }
  | { type: 'START_OUT_TRANSITION' }
  | { type: 'COMPLETE_OUT_TRANSITION' }
  | { type: 'CHECK_PENDING' }
  | { type: 'IMMEDIATE_CHANGE', to: string, wasBack: boolean }
  | { type: 'NAV_REQUEST', to: string, wasBack: boolean }

  ;
const NavigationMachine = setup({
  types: {
    events: {} as NavigationMachineEvents,
    context: {} as {
      currentLocation: string,
      currentLocationWasBack: boolean,
      pendingLocation: string | undefined,
      pendingLocationWasBack: boolean
      queuedLocation: string | undefined,
      queuedLocationWasBack: boolean
    },
    input: {} as {
      currentLocation: string,
      pendingLocation: undefined | string,
    }
  },
  guards: {
    hasPending: ({ context }) => {
      // console.log('pending guard');
      return !!context.pendingLocation;
    }
  }
}).createMachine({
  id: 'NavigationMachine',
  initial: 'init',
  context: ({ input }) => {
    return {
      currentLocation: input.currentLocation,
      currentLocationWasBack: false,
      pendingLocation: input.pendingLocation,
      pendingLocationWasBack: false,
      queuedLocation: undefined,
      queuedLocationWasBack: false,
    };
  },
  states: {
    init: {
      on: {
        START: {
          target: 'initLoading',
          actions: assign({
            pendingLocation: ({ event }) => {
              return event.to;
            }
          })
        }
      }
    },
    initLoading: {
      type: 'parallel',
      states: {
        loading: {
          initial: 'init',
          states: {
            init: {
              on: { START_LOAD: 'pending' }
            },
            pending: {
              on: {
                COMPLETE_LOAD: 'complete'
              }
            },
            complete: {
              type: 'final'
            }
          }
        },
        transitioningIn: {
          initial: 'beforeStart',
          states: {
            beforeStart: {
              on: { START_IN_TRANSITION: 'transitioning' }
            },
            transitioning: {
              on: {
                COMPLETE_IN_TRANSITION: 'complete'
              }
            },
            complete: {
              type: 'final'
            }
          }
        },
      },
      onDone: {
        target: 'viewing'
      },
      exit: [
        assign({
          currentLocation: ({ context }) => context.pendingLocation ? context.pendingLocation : context.currentLocation,
          currentLocationWasBack: ({ context }) => context.pendingLocationWasBack,
          pendingLocation: ({ context }) => context.queuedLocation,
          pendingLocationWasBack: ({ context }) => context.queuedLocationWasBack,
          queuedLocation: undefined,
          queuedLocationWasBack: false
        })
      ]
    },
    viewing: {
      on: {
        NAV_REQUEST: [
          {
            target: 'transition',
            actions: [assign({
              pendingLocation: ({ event }) => event.to,
              pendingLocationWasBack: ({ event }) => event.wasBack
            })],
          }
        ],
        CHECK_PENDING: {
          target: 'transition',
          actions: [log('entry pending check'),
          assign({
            pendingLocation: ({ context }) => context.queuedLocation,
            pendingLocationWasBack: ({ context }) => context.queuedLocationWasBack,
            queuedLocation: undefined,
            queuedLocationWasBack: false
          })
          ],
          guard: 'hasPending'
        },
        IMMEDIATE_CHANGE: {
          actions: [assign({
            currentLocation: ({ event }) => event.to
          })]
        }
      },
      // after: {
      //   1: {
      //     actions: [
      //       raise({ type: 'CHECK_PENDING' })
      //     ]
      //   }
      // }
    },
    transition: {
      type: 'parallel',
      on: {
        NAV_REQUEST: {
          actions: [assign({
            queuedLocation: ({ event }) => event.to,
            queuedLocationWasBack: ({ event }) => event.wasBack
          }), ({ event }) => {
            console.warn(`Request to nav to ${event.to} during transition. NavContext should not allow this.`);
          }],
        },
      },
      states: {
        loading: {
          initial: 'init',
          states: {
            init: {
              on: { START_LOAD: 'pending' }
            },
            pending: {
              on: {
                COMPLETE_LOAD: 'complete'
              }
            },
            complete: {
              type: 'final'
            }
          }
        },
        transitioningIn: {
          initial: 'beforeStart',
          states: {
            beforeStart: {
              on: { START_IN_TRANSITION: 'transitioning' }
            },
            transitioning: {
              on: {
                COMPLETE_IN_TRANSITION: 'complete'
              }
            },
            complete: {
              type: 'final'
            }
          }
        },
        transitioningOut: {
          initial: 'beforeStart',
          states: {
            beforeStart: {
              on: { START_OUT_TRANSITION: 'transitioning' }
            },
            transitioning: {
              on: {
                COMPLETE_OUT_TRANSITION: 'complete'
              }
            },
            complete: {
              type: 'final'
            }
          }
        }
      },
      onDone: {
        target: 'viewing'
      },
      exit: [
        assign({
          currentLocation: ({ context }) => context.pendingLocation ? context.pendingLocation : context.currentLocation,
          currentLocationWasBack: ({ context }) => context.pendingLocationWasBack,
          pendingLocation: ({ context }) => context.queuedLocation,
          pendingLocationWasBack: ({ context }) => context.queuedLocationWasBack,
          queuedLocation: undefined,
          queuedLocationWasBack: false

        })
      ]
    },
  }
});

export default NavigationMachine;